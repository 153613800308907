import { UiPageTitle } from 'fitify-ui-onboarding/src/components/UiPageTitle'
import { PageTitleProps } from 'fitify-ui-onboarding/src/types'

export function PageTitle(props: PageTitleProps) {
  return (
    <UiPageTitle
      {...props}
      suffix="FITIFY"
      defaultOgImage="https://static.gofitify.com/images/og/fitify-plus-og-image.png"
    />
  )
}
