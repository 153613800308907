const ONBOARDING_IMAGES = {
  common: {
    logo: {
      width: 145,
      height: 40,
      srcSet: ['/images/logo.webp', '/images/logo@2x.webp'],
    },
  },
  home: {
    coachesGroup: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/people/coachesGroup.webp',
        '/images/people/coachesGroup@2x.webp',
      ],
    },
  },
  subscription: {
    phones: {
      width: 275,
      height: 419,
      srcSet: [
        '/images/subscription/phones.webp',
        '/images/subscription/phones@2x.webp',
      ],
    },
    specialOffer: {
      width: 170,
      height: 170,
      srcSet: [
        '/images/subscription/promo_gift.webp',
        '/images/subscription/promo_gift@2x.webp',
      ],
    },
    moneyBack: {
      width: 519,
      height: 272,
      srcSet: [
        '/images/subscription/money-back-guarantee.webp',
        '/images/subscription/money-back-guarantee@2x.webp',
      ],
    },
  },
  salesPitches: {
    numberOfClientsM: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/sales-pitches/numberofclients-male.webp',
        '/images/sales-pitches/numberofclients-male@2x.webp',
      ],
    },
    numberOfClientsF: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/sales-pitches/numberofclients-female.webp',
        '/images/sales-pitches/numberofclients-female@2x.webp',
      ],
    },
    weightGoal: {
      width: 480,
      height: 222,
      srcSet: [
        '/images/sales-pitches/weight-goal.webp',
        '/images/sales-pitches/weight-goal@2x.webp',
      ],
    },
    ourTrainers: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/sales-pitches/our-trainers.webp',
        '/images/sales-pitches/our-trainers@2x.webp',
      ],
    },
    moreQuestionsM: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/sales-pitches/few-more-questions-male.webp',
        '/images/sales-pitches/few-more-questions-male@2x.webp',
      ],
    },
    moreQuestionsF: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/sales-pitches/few-more-questions-female.webp',
        '/images/sales-pitches/few-more-questions-female@2x.webp',
      ],
    },
    appleWatch: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/sales-pitches/apple-watch-sharing.webp',
        '/images/sales-pitches/apple-watch-sharing@2x.webp',
      ],
    },
  },
} as const satisfies Record<string, Record<string, OnboardingImage>>

type OnboardingImageKeys = keyof typeof ONBOARDING_IMAGES
type OnboardingImages<Page extends OnboardingImageKeys> =
  keyof (typeof ONBOARDING_IMAGES)[Page]

interface OnboardingImage {
  srcSet: string[]
  width?: number
  height?: number
  alt?: string
}
type OnboardingImageMap = {
  [key in OnboardingImageKeys]: {
    [image in OnboardingImages<key>]: OnboardingImage
  }
}
function getImage<T extends OnboardingImageKeys, I extends OnboardingImages<T>>(
  page: T,
  image: I
): OnboardingImage {
  const map: OnboardingImageMap = ONBOARDING_IMAGES

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return map[page][image]
}

export function getImageProps<
  T extends OnboardingImageKeys,
  I extends OnboardingImages<T>,
>(page: T, image: I) {
  const target: OnboardingImage = getImage(page, image)

  return {
    src: target?.srcSet[0] || '',
    srcSet: target?.srcSet.map((x, i) => `${x} ${i + 1}x`).join(',') || '',
    width: target?.width || 0,
    height: target?.height || 0,
  }
}

export async function preloadImages(page: OnboardingImageKeys) {
  const map: {
    [key: string]: { [image: string]: OnboardingImage }
  } = ONBOARDING_IMAGES
  if (!map[page]) return
  const target = Object.values(map[page])
  return Promise.all(
    target.map((item) => {
      return new Promise((resolve, reject) => {
        const img = new Image(item.width, item.height)
        img.src = item.srcSet[0]
        img.srcset = item.srcSet.map((x, i) => `${x} ${i + 1}x`).join(',')

        img.onload = resolve
        img.onerror = reject
      })
    })
  )
}
