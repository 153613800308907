import { getAuth } from 'firebase/auth'

import { RedeemVoucherResponseData } from '../types/nextApi'

const getAuthorizationHeader = async (): Promise<HeadersInit> => {
  const auth = getAuth()

  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken()
    return {
      Authorization: token,
    }
  }

  return {}
}

export const fetchRedeemVoucher = async (
  code: string
): Promise<RedeemVoucherResponseData> => {
  const authorizationHeader = await getAuthorizationHeader()
  const response = await fetch('/api/redeem-voucher', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeader,
    },
    body: JSON.stringify({
      code,
    }),
  })
  return response.json() as Promise<RedeemVoucherResponseData>
}
