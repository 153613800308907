import { useEffect } from 'react'

export const useBeforeLeaveDialog = (isEnabled = true) => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
      if (isEnabled) {
        e.preventDefault()
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isEnabled])
}
