import Head from 'next/head'
import { useRouter } from 'next/router'

import { UiPageTitleProps } from '../types/pageTitle'

export const UiPageTitle = ({
  canonical,
  defaultOgImage,
  description,
  metaData,
  noIndex,
  suffix,
  title,
}: UiPageTitleProps) => {
  const router = useRouter()
  const titleWithSuffix = title?.trim() ? `${title} | ${suffix}` : suffix
  const cleanTitle = titleWithSuffix.replace(/<\/?[^>]+(>|$)/g, '') // strips html tags from title

  const canonicalUrl = (
    process.env.BASE_URL + (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0]

  return (
    <Head>
      <title>{cleanTitle}</title>
      <meta property="description" content={description} />
      <meta property="og:title" content={titleWithSuffix} key="title" />
      <meta property="og:description" content={description} key="description" />
      <meta property="og:image" content={metaData?.image || defaultOgImage} />
      {canonical ? <link rel="canonical" href={canonicalUrl} /> : null}
      {noIndex ? <meta name="robots" content="noindex,nofollow" /> : null}
    </Head>
  )
}
